import { useEffect, useRef, useState } from 'react';
import PSPDFKit from 'pspdfkit';
import { setPdfInstance } from '../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetPdfUrlQuery } from '../services/pdf/pdfApi';

const licenseKey = process.env.REACT_APP_PSPDF_LICENSE_KEY;
const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;

const PDFViewerComponent = ({ currentPage, currentPageTrigger, currentBoundingBoxes, height, customUrl = null, isOpen = true }) => {
  const documentData = useSelector((state) => state.Document.documentData);
  const documentId = documentData?.documentId;

  const { data: { demandS3Url } = {}, error, isLoading: isPdfUrlLoading } = useGetPdfUrlQuery(documentId, {
    skip: !documentId || customUrl,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  //create custom file name
  const location = useLocation();
  const envPrefix = process.env.NODE_ENV === 'development' ? 'DEV_' : location.pathname.toLowerCase().includes('beta') ? 'BETA_' : '';
  const coverageType = (documentData?.claimInfo?.claimCoverage + '_' || '');
  const defaultFileName = 'Settlement_Package';
  const claimantFullName = (documentData?.claimInfo?.claimant?.firstName || '') + (documentData?.claimInfo?.claimant?.lastName ? `_${documentData?.claimInfo?.claimant?.lastName}` : '');
  const firmCaseNumber = documentData?.sendingFirm?.firmCaseNumber;
  const customFileName = `${envPrefix}${coverageType}${defaultFileName}${firmCaseNumber ? `_${firmCaseNumber}` : ''}${claimantFullName ? `_${claimantFullName}` : ''}.pdf`;

  const [instance, setInstance] = useState(null);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const documentUrl = customUrl || demandS3Url;

  useEffect(() => {
    containerRef.current && PSPDFKit.unload(containerRef.current);

    documentUrl && PSPDFKit.load({
      styleSheets: [
        // `${baseUrl}pspdfkit.css`,
        `${baseUrl}customPSPDFstyles.css`
      ],
      licenseKey,
      container: containerRef.current,
      baseUrl: baseUrl,
      inlineTextSelectionToolbarItems: () => [],
      document: documentUrl,
      initialViewState: new PSPDFKit.ViewState({
        zoom: PSPDFKit.ZoomMode.FIT_TO_WIDTH,
      }),
    }).then((instance) => {
      const toolbarItemsToRemove = [
        'sidebar-document-outline',
        'sidebar-annotations',
        'sidebar-bookmarks',
        'annotate',
        'ink',
        'highlighter',
        'text-highlighter',
        'ink-eraser',
        'signature',
        'image',
        'stamp',
        'note',
        'text',
        'callout',
        'line',
        'link',
        'arrow',
        'rectangle',
        'ellipse',
        'polygon',
        'cloudy-polygon',
        'polyline',
        'document-crop',
        'multi-annotations-selection',
        'pan',
        'print',
        'sidebar-signatures',
        'sidebar-layers',
        'document-editor',
        'export-pdf',
      ];

      // Custom export button
      const customExportPdfButton = {
        type: "custom",  // custom type required for custom buttons
        id: "export",
        title: "Export",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v13.19l2.72-2.72a.75.75 0 1 1 1.06 1.06L12 19.06l-4.53-4.53a.75.75 0 1 1 1.06-1.06l2.72 2.72V3a.75.75 0 0 1 .75-.75Zm-9 14a.75.75 0 0 1 .75.75v3c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25v-3a.75.75 0 0 1 1.5 0v3A2.75 2.75 0 0 1 19 22.75H5A2.75 2.75 0 0 1 2.25 20v-3a.75.75 0 0 1 .75-.75Z"/></svg>`,
        onPress: (event) => {
          instance.exportPDF().then((pdf) => {
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = window?.document?.createElement
            if (a) {
              a.href = url;
              a.download = customFileName
              a.click();
            }
          });
        }
      };

      instance.setToolbarItems(instance.toolbarItems
        .filter((item) => !toolbarItemsToRemove.includes(item.type))
        .concat(customExportPdfButton));

      instance.addEventListener('annotations.load', (loadedAnnotations) => {
        dispatch(setPdfInstance(instance));
      });

      setInstance(instance);

      return () => {
        dispatch(setPdfInstance(null));
        containerRef.current && PSPDFKit.unload(containerRef.current);
      };
    });
  }, [documentUrl, isOpen]);

  useEffect(() => {
    const handlePspdfKitInstanceUpdate = async () => {
      const newState = instance.viewState.set('currentPageIndex', currentPage);

      // Find and clear all existing annotations
      const oldAnnotations = await Promise.all(
        Array.from({
          length: instance.totalPageCount
        }).map((_, pageIndex) => instance.getAnnotations(pageIndex))
      );
      if (oldAnnotations.length > 0) {
        const oldAnnotationIds = oldAnnotations.flatMap((pageAnnotations) =>
          pageAnnotations.map((annotation) => annotation.id).toArray()
        );
        await instance.delete(oldAnnotationIds);
      }

      // Create new BBox annotations
      if (currentBoundingBoxes.length > 0) {
        const pageInfo = instance.pageInfoForIndex(currentPage);
        const annotations = currentBoundingBoxes.map((boundingBox) => {
          if (boundingBox && boundingBox.left) {
            const annotationRect = new PSPDFKit.Geometry.Rect({
              left: boundingBox.left * pageInfo.width,
              top: boundingBox.top * pageInfo.height,
              width: (boundingBox.right - boundingBox.left) * pageInfo.width,
              height: (boundingBox.bottom - boundingBox.top) * pageInfo.height,
            });
            const rects = PSPDFKit.Immutable.List([annotationRect]);

            return new PSPDFKit.Annotations.HighlightAnnotation({
              pageIndex: currentPage,
              rects: rects,
              boundingBox: PSPDFKit.Geometry.Rect.union(rects),
            });
          }
        });

        await instance.create(annotations);
      }
      instance.setViewState(newState);
    };
    instance && handlePspdfKitInstanceUpdate();
  }, [currentPageTrigger]);

  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: '100%', height: height }} />;
};

export default PDFViewerComponent;
