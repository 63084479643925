import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLiveTemplateById, getTemplateIdByVersion, getDocumentUsageForTemplate, bumpTemplatePinforDocument } from '../../api';
import { setDocumentDataLoading } from '../slices/documentSlice';
import { setToast } from '../slices/globalToastSlice';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';
import { setPdfUrlPollingLoadingStatus } from '../../services/pdf/customSlice';

export const fetchTemplateData = createAsyncThunk('Templates/fetchTemplateData', async ({ templateId, user }, { dispatch, rejectWithValue }) => {
  try {
    if (!user || !templateId) {
      return;
    }
    const response = await getLiveTemplateById(templateId, user);
    return response['template'];
  } catch (err) {
    return rejectWithValue(new Error('Failed to fetch template data for template: ' + templateId));
  }
});

export const fetchTemplateSpecificVersionData = createAsyncThunk('Templates/fetchTemplateSpecificVersionData', async ({ templateId, version, user }, { dispatch, rejectWithValue }) => {
  try {
    if (!user || !templateId) {
      return;
    }
    if (isNaN(version)) {
      return rejectWithValue(new Error('Version must be a number'));
    }
    const response = await getTemplateIdByVersion(templateId, version, user);
    return response['template'];
  } catch (err) {
    return rejectWithValue(new Error('Failed to fetch template data for specific version: ' + templateId + ' v' + version));
  }
});


export const fetchTemplateDocumentUsage = createAsyncThunk('Templates/fetchDocumentUsage', async ({ templateId, user }, { dispatch, rejectWithValue }) => {
  try {
    if (!user || !templateId) {
      return;
    }
    const response = await getDocumentUsageForTemplate(templateId, user);
    return response;
  } catch (err) {
    return rejectWithValue(new Error('Failed to fetch document usage'));
  }
});

export const bumpTemplatePin = createAsyncThunk(
  'Templates/bumpTemplatePin',
  async ({ documentId, user, templateId }, { dispatch, rejectWithValue, getState }) => {
    try {
      if (!user || !documentId) {
        throw new Error('Missing user or documentId');
      }

      const response = await bumpTemplatePinforDocument(documentId, user);

      if (response.status !== 200) {
        throw new Error(`Failed with status code ${response.status}`);
      }

      await dispatch(fetchTemplateData({ templateId, user }));
      dispatch(setDocumentDataLoading(documentId));
      dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));
      dispatch(pollPdfRegenerationStatus({ documentId, user }))
      dispatch(setToast({ isOpen: true, message: 'Template updated', severity: 'success' }));
    } catch (err) {
      const error_message = 'Failed to bump template pin: ' + err.toString();
      dispatch(setToast({ isOpen: true, message: error_message, severity: 'error' }));
      return rejectWithValue(error_message);
    }
  }
);