// Note: custom slices are not required. createAPI creates a slice by default-that is not editable -but used with for hooks, matchers, etc.
// custom slices -like this- are useful for keeping complex state management consolidated, and out of api files.
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    demandS3RegenerationLoadingData: {},
};

export const PdfCustomSlice = createSlice({
    name: 'PdfCustomSlice',
    initialState,
    reducers: {
        setPdfUrlPollingLoadingStatus: (state, action) => {
            state.demandS3RegenerationLoadingData = {
                ...state.demandS3RegenerationLoadingData,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        // add cases and matchers here
    }
});

export const { setPdfUrlPollingLoadingStatus } = PdfCustomSlice.actions;

export default PdfCustomSlice.reducer;