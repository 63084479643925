import React, { useState, useEffect } from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import './CustomFieldLoadingAnimation.css';

const Ellipsis = () => {
    return (
        <div className="ellipsis"><span>.</span><span>.</span><span>.</span></div>
    );
};

const CustomFieldLoadingAnimation = ({ aiLoading }) => {
    const [message, setMessage] = useState(''); // To store and display the current message
    const [progress, setProgress] = useState(0); // Initial progress is 0%
    const totalDuration = 90000; // 90 seconds in milliseconds

    useEffect(() => {
        if (!aiLoading) {
            // Reset progress to 0 if not loading
            setProgress(0);
            return; // Skip the logic below if loading is not active
        }

        let startTime = Date.now();

        // Function to calculate the progress based on elapsed time
        const updateProgress = () => {
            const elapsedTime = Date.now() - startTime;
            const percentage = Math.min((elapsedTime / totalDuration) * 100, 100); // Ensure it caps at 100%
            setProgress(percentage);
        };

        // Initial message and progress reset
        setMessage('Evaluating case details');
        setProgress(0); // Ensure it starts from 0

        // Set up timeouts for each phase
        const timeouts = [
            setTimeout(() => setMessage('Evaluating case details'), 0), // Start immediately
            setTimeout(() => setMessage('Reviewing files'), 3000), // After 3 seconds
            setTimeout(() => setMessage('Extracting key information'), 6000), // After another 3 seconds (total 6 seconds)
            setTimeout(() => setMessage('Composing AI summary'), 10000), // After another 4 seconds (total 10 seconds)
            setTimeout(() => setMessage('Sorry, this is taking a little longer than expected'), 30000), // After another 20 seconds (total 30 seconds)
        ];

        // Start updating progress every 100ms
        const interval = setInterval(updateProgress, 100);

        // Clear timeouts and interval when component unmounts or `aiLoading` changes
        return () => {
            timeouts.forEach(clearTimeout);
            clearInterval(interval);
        };
    }, [aiLoading]); // Depend on `aiLoading` so it resets correctly when loading starts/stops

    return (
        <>
            {aiLoading &&
                <Box
                    sx={{
                        position: 'absolute',  // Confines the overlay to the editor's area
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',  // Semi-transparent background
                        zIndex: 10,  // Make sure it appears above the editor content
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pointerEvents: 'none', // Ensures that the overlay doesn’t block user interactions

                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',  // Confines the overlay to the editor's area
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            borderRadius: '4px'
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            color='secondary'
                        />
                    </Box>
                    <Stack
                        sx={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant='h6' color={'primary'}>
                            {message} <Ellipsis />
                        </Typography>
                    </Stack>

                </Box>
            }
        </>
    );
};

export default CustomFieldLoadingAnimation;



