import { createSlice } from '@reduxjs/toolkit';
import { fetchMedicalsData, postMedicalsData } from '../thunks/medicalsData';

const originalMedicalsState = {
    medicalsLoading: false,
    medicals: {
        medicalTreatments: [],
    },
};

const medicalsSlice = createSlice({
    name: 'Medicals',
    initialState: originalMedicalsState,
    reducers: {
        setMedicalsTreatments: (state, action) => {
            state.medicals.medicalTreatments = action.payload;
        },
        setMedicalsLoading: (state, action) => {
            state.medicalsLoading = action.payload;
        }
    },
    extraReducers: (builder) => {

        // get medicals data
        builder

            .addCase(fetchMedicalsData.fulfilled, (state, action) => {
                if (action.payload) {
                    state.medicals = action.payload
                }
                state.medicalsLoading = false
            })

            .addCase(fetchMedicalsData.pending, (state, action) => {
                state.medicalsLoading = true;
            })

            .addCase(fetchMedicalsData.rejected, (state, action) => {
                state.medicalsLoading = false;
            })

            // post medicals data
            .addCase(postMedicalsData.fulfilled, (state, action) => {
                state.medicalsLoading = false;
            })

            .addCase(postMedicalsData.pending, (state, action) => {
                state.medicalsLoading = true;
            })

            .addCase(postMedicalsData.rejected, (state, action) => {
                state.medicalsLoading = false;
            })
    }
});

export const {
    setMedicalsTreatments,
    setMedicalsLoading
} = medicalsSlice.actions;

export default medicalsSlice.reducer;