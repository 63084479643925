import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToast, setApiErrorToast } from '../redux/slices/globalToastSlice';
import { Snackbar, Alert, Paper } from '@mui/material';

const Toast = () => {
  const { toast, apiErrorToast } = useSelector((state) => state.GlobalToasts);
  const { isOpen, severity, message } = toast; 
  const duration = toast?.duration || 5000;
  const anchorOrigin = toast?.anchorOrigin || { vertical: 'bottom', horizontal: 'right' };

  const { isOpen: apiErrorIsOpen, message: apiErrorMessage } = apiErrorToast;
  const apiErrorDuration = apiErrorToast?.duration || undefined; //make sticky with option to close by default unlesss duration is set
  const apiErrorAnchorOrigin = apiErrorToast?.anchorOrigin || { vertical: 'top', horizontal: 'left' };
  const apiErrorSeverity = apiErrorToast?.severity || 'error';


  const dispatch = useDispatch();

  const handleCloseToast = () => dispatch(setToast({ isOpen: false, message: '' }));
  const handleApiErrorToastClose = () => dispatch(setApiErrorToast({ isOpen: false, message: '' }));

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleCloseToast}
        anchorOrigin={anchorOrigin}
      >
        <Paper elevation={8}>
          <Alert onClose={handleCloseToast} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar
        open={apiErrorIsOpen}
        autoHideDuration={apiErrorDuration}
        onClose={handleApiErrorToastClose}
        anchorOrigin={apiErrorAnchorOrigin}
      >
        <Paper elevation={8}>
          <Alert onClose={handleApiErrorToastClose} severity={apiErrorSeverity} sx={{ width: '100%' }}>
            {apiErrorMessage.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}

          </Alert>
        </Paper>
      </Snackbar>
    </>
  );
};

export default Toast;
