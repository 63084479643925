import { createAsyncThunk } from '@reduxjs/toolkit'
import awsExports from '../../aws-exports';
import { postDocumentFilesByIdsApi } from '../../api';
import { setToast } from '../slices/globalToastSlice';
import { apiSlice } from '../../services/apiSlice';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';
import { setPdfUrlPollingLoadingStatus } from '../../services/pdf/customSlice';
const rootApiUrl = awsExports.ROOT_API_URL;

export const fetchInventoryDemandData = createAsyncThunk(
    'Inventory/fetchInventoryDemandDataStatus',
    async ({ requestBody, token }, { }) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(rootApiUrl + "/inventory", settings)
            .then((response) => response.json())

        return { response, isActive: requestBody.active_filter };
    },
)

export const fetchAuthorFormData = createAsyncThunk(
    'Author/fetchAuthorFormDataStatus',
    async ({ token, user, customerId = null, hasPermission = false }, { rejectWithValue }) => {
        if (hasPermission) {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({ customerId: customerId })
            };

            const response = await fetch(
                rootApiUrl + "/authorFormData", settings
            ).then((response) => response.json())

            return { response, user };
        } else {
            return rejectWithValue({ error: 'User does not have permission to view this data' });
        }
    }
)

export const saveDocumentFilesBydId = createAsyncThunk(
    'DemandDom/saveDocumentFilesBydIdStatus',
    async ({ documentId, files, user }, { dispatch, rejectWithValue }) => {
        dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));
        const response = await postDocumentFilesByIdsApi(documentId, files, user)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
                    dispatch(pollPdfRegenerationStatus({ documentId, user }));
                    dispatch(setToast({ isOpen: true, severity: 'success', message: 'Files updated successfully' }));
                    return response.json();
                } else {
                    dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                    return rejectWithValue(response.json());
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                return rejectWithValue(error);
            });

        return response;
    }
);
