import dayjs from 'dayjs';
import { format } from 'date-fns';
import { useEffect, useMemo, useRef } from "react";
import { debounce } from 'lodash'
import moment from 'moment'
import { isCompositeDemand } from './DemandComponents/helpers';
import { isLawFirmUser } from './common-roles';
import { green, lightBlue, purple } from '@mui/material/colors';

export const DEFAULT_PAGE_SIZE = 50;
export const DATE_FORMAT = Intl.DateTimeFormat('en-US', { month: "2-digit", day: "2-digit", year: "numeric", timeZone: 'UTC' });
export const DATE_FORMAT_EST = Intl.DateTimeFormat('en-US', { month: "2-digit", day: "2-digit", year: "numeric", timeZone: 'America/New_York' });
export const DATE_TIME_FORMAT = Intl.DateTimeFormat('en-US', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: 'h23', timeZone: 'America/New_York' });
export const USD_FORMAT = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const DECIMAL_FORMAT = Intl.NumberFormat('en-US');
export const TIME_FORMAT = Intl.DateTimeFormat('en-US', { hour: "2-digit", minute: "2-digit", hour12: false, timeZone: 'America/New_York' });
export const DATE_TIME_FORMAT_LOCAL_TZ = Intl.DateTimeFormat('en-US', { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: 'h23', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
export const MAX_EMAIL_FILESIZE_LIMIT = (24 * 1024 * 1024); // 24MB
export const DATE_FILTER_OPERATORS = ['is', 'before', 'after', 'onOrAfter', 'onOrBefore'];

export const CONTACT_EMAIL = "helpdesk@precedent.com";

// Normalize an email address:
// - Lowercase the string
// - Remove whitespace
export const normalizeEmailAddress = (emailAddress) => {
    try {
        if (emailAddress && typeof emailAddress === 'string') {
            return emailAddress.toLowerCase().trim();
        }
    }
    catch {
    }

    return emailAddress;
};

//
// Format a date string that does not have a time component associated with it
// Example: 2023-11-01
//
export const formatDate = (dateStr) => {
    try {
        if (dateStr && dateStr !== "") {
            return DATE_FORMAT.format(Date.parse(dateStr));
        }
    }
    catch {
    }

    return "";
};

export const formatDateEst = (dateStr) => {
    try {
        if (dateStr && dateStr !== "") {
            return DATE_FORMAT_EST.format(Date.parse(dateStr));
        }
    }
    catch {
    }
    return "";
};

export const formatDateForForm = dateString => dateString ? moment(dateString).format('YYYY-MM-DD') : undefined

// This function is needed to fix the off-by-1 error when putting in a date, since it is converted to UTC and goes back a day
export const convertUTCDateToLocalDate = (date) => {
    try {
        var newDate = formatDate(date);
        return format(new Date(newDate), 'MM/dd/yyyy')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const convertUTCDateTimeToLocalDate = (date) => {
    try {
        var newDate = formatDateTimeLocal(date);
        return format(new Date(newDate), 'MM/dd/yyyy')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const convertUTCDateTimeToLocalTime = (date) => {
    var newDate = null;
    try {
        newDate = formatDateTimeLocal(date);
        return format(new Date(newDate), 'HH:mm')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const convertUTCDateToLocalDateForEdit = (date) => {
    try {
        var newDate = formatDate(date);
        return format(new Date(newDate), 'yyyy-MM-dd')
    }
    catch {
        console.log("Could not convert date: " + date);
    }

    return "";
}

export const getCurrentDate = () => {
    return dayjs().format("YYYY-MM-DD");
};

export const cleanNumber = num => num ? Number(num.toString().replace(/[$,]/g, '')) : '';

// Format a date object that does have a time component associated with it. This will
// format the date with the user's local timezone
//
export const formatDateTimeLocal = (dateObj) => {
    try {
        if (dateObj) {
            return DATE_TIME_FORMAT_LOCAL_TZ.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

//
// Format a date object that does have a time component associated with it. This will
// format the date in American/New_York timezone
//
export const formatDateTime = (dateObj) => {
    try {
        if (dateObj) {
            return DATE_TIME_FORMAT.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

export const formatTime = (dateObj) => {
    try {
        if (dateObj) {
            return TIME_FORMAT.format(dateObj);
        }
    }
    catch {
    }
    return "";
};

export const formatCurrency = (numStr) => {
    if (numStr !== undefined) {
        numStr = numStr + "";
        numStr = numStr.replace("$", "").replaceAll(",", "");
        if (!isNaN(numStr))
            return USD_FORMAT.format(numStr);
    }

    return "";
};

export const formatDecimal = (numStr) => {

    // If the value is a number then convert to string
    if (numStr !== undefined && typeof numStr === 'number') {
        numStr = numStr + "";
    }

    if (numStr !== undefined && typeof numStr === 'string') {
        // Remove non-numeric characters, except for the first decimal point
        let cleanedNumStr = numStr.replace(/[^\d.]/g, '');

        //if there is more than one decimal point, remove all but the first one
        const decimalIndex = cleanedNumStr.indexOf('.');
        if (decimalIndex !== -1) {
            cleanedNumStr = cleanedNumStr.substring(0, decimalIndex + 1) + cleanedNumStr.substring(decimalIndex + 1).replace('.', '');
        }

        // Split the string at the decimal point
        const parts = cleanedNumStr.split('.');

        if (parts.length > 0) {
            // Format the integer part with commas
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Ensure there's at most one decimal point and at most two digits past the decimal
            if (parts.length > 1) {
                parts[1] = parts[1].substring(0, 2); // Keep at most 2 decimal places
            }
        }

        // Rejoin the integer and fractional parts with a single decimal point
        cleanedNumStr = parts.join('.');

        return cleanedNumStr;
    }

    return "";
};


//this function is used to format phone number. It should allow only numbers, and and x to indicate extension
//It should correctly place parenthesis and dashes for the phone number
//we are ONLY concerned with US numbers here. The extension is optional, but it must all be numbers besides the singular x
//valid example: (407) 867-4877 x5202
export const formatPhoneNumber = (numStr) => {
    if (numStr !== undefined) {
        // Remove all non-numeric characters except 'x' for the extension.
        const cleanedStr = numStr.replace(/[^0-9x]/g, '');

        // Check if 'x' for extension exists in the string.
        const extensionIndex = cleanedStr.indexOf('x');

        // Split the cleaned string into the number and extension parts.
        let numberPart, extensionPart;
        if (extensionIndex !== -1) {
            numberPart = cleanedStr.substring(0, extensionIndex);
            extensionPart = cleanedStr.substring(extensionIndex + 1);
        } else {
            numberPart = cleanedStr;
        }

        // Format the number part as (XXX) XXX-XXXX.
        let formattedNumber = '';
        if (numberPart.length >= 10) {
            formattedNumber = `(${numberPart.substring(0, 3)}) ${numberPart.substring(3, 6)}-${numberPart.substring(6, 10)}`;
        } else if (numberPart.length >= 7) {
            formattedNumber = `(${numberPart.substring(0, 3)}) ${numberPart.substring(3, 6)}-${numberPart.substring(6)}`;
        } else {
            formattedNumber = numberPart;
        }

        // Combine the formatted number and extension (if it exists).
        if (extensionPart !== undefined) {
            return `${formattedNumber} x${extensionPart}`;
        } else {
            return formattedNumber;
        }
    }
    return "";
};


export const formatAddress = (address) => {
    var addressStr = "";

    if (address) {
        addressStr += address.street1;
        if (address.street2 !== "") {
            addressStr += ", " + address.street2;
        }

        addressStr += ", " + address.city;
        addressStr += ", " + address.state;
        addressStr += " " + address.zipCode
    }

    return addressStr;
};

export const mapStatusToLabel = (status) => {

    switch (status) {
        case "DocumentUploaded":
            return "New submission";
        case "DocumentSubmitted":
            return "Delivered to carrier";
        case "DocumentApproved":
            return "Approved by law firm";
        case "DocumentReceived":
            return "Receipt acknowledged";
        case "DocumentArchived":
            return "Archived";
        case "WaitingFirmApproval":
            return "Awaiting firm approval";
        case "AwaitingFirmReview":
            return "Awaiting firm review";
        case "RejectedByLawFirm":
            return "Awaiting Precedent review";
        case "AwaitingPrecedentSignoff":
            return "Awaiting Precedent signoff";
        case "AwaitingMedicalExtraction":
            return "AI processing";
    }

    return status;
};

export const mapStatusToChipClass = (status) => {
    switch (status) {
        case "DocumentUploaded":
            return "status-chip pending";
        case "DocumentSubmitted":
            return "status-chip submitted";
        case "DocumentApproved":
            return "status-chip approved";
        case "DocumentReceived":
            return "status-chip received";
        case "DocumentArchived":
            return "status-chip archived";
        case "WaitingFirmApproval":
            return "status-chip waitingapproval";
        case "AwaitingFirmReview":
            return "status-chip waitingreview";
        case "RejectedByLawFirm":
            return "status-chip rejected";
        case "AwaitingPrecedentSignoff":
            return "status-chip awaitingprecedentsignoff";
        case "AwaitingMedicalExtraction":
            return "status-chip awaitingmedicalextraction";
    }

    return "status-chip";
};

export const mapMedicalDocumentsStatusToChipClass = (status) => {
    switch (status) {
        case "Processing":  // Catch all status
        case "Validating":
        case "Uploading":
        case "Attachment download success":
            return "status-chip no_medical_bills";
        case "PDF conversion failed":
            return "status-chip validation_failed";
        case "PDF compression failed":
            return "status-chip validation_failed";
        case "PDF conversion success":
        case "Attachment download queued":
            return "status-chip no_medical_bills";
        case "Attachment download failed":
            return "status-chip validation_failed";
        case "none":
            return "status-chip no_medical_bills";
        case "Validation failed":
            return "status-chip validation_failed";
        case "Upload failed":
            return "status-chip validation_failed";
        case "AI processing":
            return "status-chip awaitingmedicalextraction";
        case "MedicalsInProgress":
            return "status-chip awaitingmedicalextraction";
        case "MedicalsComplete":
            return "status-chip medical_extraction_complete";
    }
    return "status-chip";
}



export const mapMedicalDocumentsStatusToLabel = (status) => {
    switch (status) {
        case "uploading":
            return "Uploading";
        case "none":
            return "No medical charges";
        case "MedicalsInProgress":
            return "AI processing";
        case "MedicalsComplete":
            return "Processing complete";
    }
    return status;
}

export const mapStatusToChipIconColor = (status) => {
    switch (status) {
        case "DocumentUploaded":
            return purple[500];
        case "DocumentSubmitted":
            return green[900];
        case "DocumentApproved":
            return "#FFFFFF";
        case "DocumentReceived":
            return "#FFFFFF";
        case "DocumentArchived":
            return "#232941";
        case "WaitingFirmApproval":
            return "#FFFFFF";
        case "AwaitingFirmReview":
            return lightBlue[900];
        case "RejectedByLawFirm":
            return "#232941";
        case "AwaitingPrecedentSignoff":
            return "#FFFFFF";
        case "AwaitingMedicalExtraction":
            return purple[500];
    }

    return "status-chip";
}

export const mapDeliveryStatusToLabel = (status) => {

    switch (status) {
        case "AwaitingSubmission":
            return "Awaiting submission";
        case "EmailSubmitted":
            return "Email submission in progress";
        case "EmailSent":
            return "Sent via email";
        case "EmailSendFailure":
            return "Email send failed";
        case "FaxSubmitted":
            return "Fax submission in progress";
        case "FaxSubmissionFailed":
            return "Fax submission failed";
        case "FaxSent":
            return "Sent via fax";
        case "FaxSendFailure":
            return "Fax send failed";
        case "ManuallySent":
            return "Sent manually";

    }

    return status;
};

export const DOCUMENT_STATUSES_AND_DESCRIPTIONS = [
    {
        label: 'DocumentUploaded',
        description: 'A new demand created in Exchange'
    },
    {
        label: 'AwaitingMedicalExtraction',
        description: 'AI analyzing uploaded documents'
    },
    {
        label: 'AwaitingPrecedentSignoff',
        description: 'Automatic quality check of data from AI'
    },
    {
        label: 'AwaitingFirmReview',
        description: 'Review and edit the demand'
    },
    {
        label: 'WaitingFirmApproval',
        description: 'Edit as needed, approve for delivery to the carrier'
    },
    {
        label: 'DocumentApproved',
        description: 'Demand approved by the attorney'
    },
    {
        label: 'DocumentSubmitted',
        description: 'Demand sent to carrier'
    },
    {
        label: 'DocumentReceived',
        description: 'Insurance carrier acknowledged receipt of demand'
    },
]

export const deliveryStatusDropDownValues =
    [{ value: "AwaitingSubmission", label: mapDeliveryStatusToLabel("AwaitingSubmission") },
    { value: "EmailSubmitted", label: mapDeliveryStatusToLabel("EmailSubmitted") },
    { value: "EmailSendFailure", label: mapDeliveryStatusToLabel("EmailSendFailure") },
    { value: "FaxSubmitted", label: mapDeliveryStatusToLabel("FaxSubmitted") },
    { value: "FaxSubmissionFailed", label: mapDeliveryStatusToLabel("FaxSubmissionFailed") },
    { value: "FaxSendFailure", label: mapDeliveryStatusToLabel("FaxSendFailure") },
    { value: "FaxSent", label: mapDeliveryStatusToLabel("FaxSent") },
    { value: "ManuallySent", label: mapDeliveryStatusToLabel("ManuallySent") },
    { value: "EmailSent", label: mapDeliveryStatusToLabel("EmailSent") },
    ]

export const documentStatusDropDownValues =
    [
        { value: "DocumentApproved", label: mapStatusToLabel("DocumentApproved") },
        { value: "DocumentArchived", label: mapStatusToLabel("DocumentArchived") },
        { value: "WaitingFirmApproval", label: mapStatusToLabel("WaitingFirmApproval") },
        { value: "AwaitingFirmReview", label: mapStatusToLabel("AwaitingFirmReview") },
        { value: "RejectedByLawFirm", label: mapStatusToLabel("RejectedByLawFirm") },
        { value: "AwaitingPrecedentSignoff", label: mapStatusToLabel("AwaitingPrecedentSignoff") },
        { value: "AwaitingMedicalExtraction", label: mapStatusToLabel("AwaitingMedicalExtraction") },
        { value: "DocumentSubmitted", label: mapStatusToLabel("DocumentSubmitted") },
        { value: "DocumentUploaded", label: mapStatusToLabel("DocumentUploaded") },
        { value: "DocumentReceived", label: mapStatusToLabel("DocumentReceived") }
    ]


export const mapDeliveryStatusToChipClass = (status) => {
    return "status-chip " + status.toLowerCase();
};


export const mapDocumentTypeToLabel = (documentType) => {
    if (documentType === 'TRADITIONALDEMAND') {
        return "PDF";
    }
    else if (documentType === 'COMPOSITEDEMAND') {
        return "Editable";
    }

    return documentType;
};

export const documentTypeDropDownValues =
    [{ value: "COMPOSITEDEMAND", label: mapDocumentTypeToLabel("COMPOSITEDEMAND") },
    { value: "TRADITIONALDEMAND", label: mapDocumentTypeToLabel("TRADITIONALDEMAND") }
    ]

export const userHasPermission = (permissionId, user) => {
    return user && user["permissions"].includes(permissionId);
};

export const userHasRole = (roleId, user) => {
    return user && user["roles"] && user["roles"].includes(roleId);
};

export const isRequestApprovalEnabled = (documentStatus, documentType, user) => {

    // Only enable for law firm users if the composite demand is in the AwaitingFirmReview status
    if (isLawFirmUser(user)) {
        return isCompositeDemand(documentType) && documentStatus === 'AwaitingFirmReview';
    }

    return documentStatus === 'DocumentUploaded' ||
        documentStatus === 'AwaitingFirmReview' ||
        documentStatus === 'RejectedByLawFirm' ||
        documentStatus === 'AwaitingMedicalExtraction' ||
        documentStatus === 'AwaitingPrecedentSignoff';
};

export const isUploadMedicalsEnabled = (userData, documentStatus) => {
    return (userHasPermission("UploadMedicals", userData) &&
        (documentStatus === 'DocumentUploaded' || documentStatus === 'AwaitingMedicalExtraction' || documentStatus === 'AwaitingPrecedentSignoff' || documentStatus === 'RejectedByLawFirm'));
}

export const findStatusEventDate = (status, statusHistory) => {
    const matchedStatus = statusHistory.toReversed().find((element) => element.documentStatus === status);
    return matchedStatus;
};

export const doesCarrierExist = (document) => {
    return document.recipientCarrier?.carrierCustomerAliasId && document.recipientCarrier?.carrierCustomerAliasId !== ""
};


export const isValidEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    //if it is empty, or it is not in the correct format, return false
    if (!email || email.length === 0) {
        return false
    }
    else if (!emailRegex.test(email)) {
        return false;
    }
    else {
        return true;
    }
};

export const isDemandReadyForSubmission = (documentStatus) => {
    return documentStatus === 'DocumentApproved' || documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived';
};

export const isValidPhoneNumber = (phoneNumber) => {
    const number = formatPhoneNumber(phoneNumber);
    return number.length >= 10;
};

export const setCookie = (name, value, expireMinutes) => {
    var expires = "";
    if (expireMinutes) {
        var date = new Date();
        date.setTime(date.getTime() + (expireMinutes * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const useDebounce = (callback, delay = 300) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
};


export const mapDeliveryChannelToLabel = (deliveryChannelType) => {
    if (deliveryChannelType === "fax")
        return "Fax";
    else if (deliveryChannelType === "email")
        return "Email";
    else if (deliveryChannelType === "manual")
        return "Manual";
    else if (deliveryChannelType === "none")
        return "None";
    else
        return "";
};

export const getApprovalCTALabel = (documentStatus) => {
    if (documentStatus === 'AwaitingFirmReview') {
        return "Send for approval";
    }
    else {
        return "Email approval to firm";
    }
};
