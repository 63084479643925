export const ICD_CODES_URL_PATH = 'icd10_codes';
export const MRI_URL_PATH = 'mri';

export const ICD_CODES_ENTITY_INSIGHTS_TYPE = 'ICD10_CODES';
export const MRI_ENTITY_INSIGHTS_TYPE = 'MRI_FINDINGS';

export const MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE = {
  [ICD_CODES_URL_PATH]: ICD_CODES_ENTITY_INSIGHTS_TYPE,
  [MRI_URL_PATH]: MRI_ENTITY_INSIGHTS_TYPE,
};

export const INCLUDED_STATUS = 'INCLUDED';
export const EXCLUDED_STATUS = 'EXCLUDED';
export const NEEDS_REVIEW_STATUS = 'NEEDS_REVIEW';

export const mapPageInsightStatusToDisplayStatus = {
  [INCLUDED_STATUS]: 'Included',
  [EXCLUDED_STATUS]: 'Excluded',
  [NEEDS_REVIEW_STATUS]: 'Needs review',
};

export const selectPageInsightStatusOptions = [
  { value: INCLUDED_STATUS, label: mapPageInsightStatusToDisplayStatus[INCLUDED_STATUS] },
  { value: EXCLUDED_STATUS, label: mapPageInsightStatusToDisplayStatus[EXCLUDED_STATUS] },
];

export const formatBodyPartName = (bodyPart) => {
  return bodyPart.charAt(0).toUpperCase() + bodyPart.slice(1).toLowerCase();
};

export const transformMriSummaryIntoRows = (mriSummaryData) => {
  const mriNarratives = selectMriNarratives(mriSummaryData);

  const mriSummarizedFindings = [];
  mriNarratives.forEach(({ summary_name, narrative_components, body_part }) => {
    narrative_components.forEach(({ value, entity_ids, status }) => {
      mriSummarizedFindings.push({
        mriSummaryName: summary_name,
        bodyPart: body_part,
        value,
        entityIds: entity_ids,
        status,
      });
    });
  });

  return mriSummarizedFindings;
};

export const selectPageInsights = (file) => file?.fileEntityData?.pageInsights || [];
export const selectMriNarratives = (mriInsightsBody) => mriInsightsBody?.insights?.narratives || [];
