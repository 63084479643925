import { setApiErrorToast } from '../redux/slices/globalToastSlice';

export const errorHandlingMiddleWare = ({ dispatch }) => (next) => (action) => {
    if (action.type.endsWith('/rejected')) {
        // Checking meta properties to skip displaying error if thunk was cancelled - not a true error
        if (process.env.NODE_ENV === 'development' || (action?.meta?.aborted === false && action?.meta?.condition === false)) {
            console.log(action);
            if (!(action?.type || '').includes('api/')) {  // handles RTK Query api endpoints
                const message = buildCrucialErrorInfoString(action.payload);
                dispatch(setApiErrorToast({ message, isOpen: true }));
            } else {  // handles other rejected actions/thunks
                const message = buildCrucialErrorInfoString(action);
                dispatch(setApiErrorToast({ message, isOpen: true }));
            }
        }
    }
    return next(action);
};

const buildCrucialErrorInfoString = (errorResponse) => {
    let crucialInfo = '';

    if (errorResponse.error?.message) {
        crucialInfo += `Error: ${errorResponse.error.message}\n`;
    }
    if (errorResponse.meta?.baseQueryMeta?.response?.status) {
        crucialInfo += `Status: ${errorResponse.meta.baseQueryMeta.response.status}\n`;
    }
    if (errorResponse.meta?.arg?.endpointName) {
        crucialInfo += `Endpoint: ${errorResponse.meta.arg.endpointName}\n`;
    }
    if (errorResponse.meta?.baseQueryMeta?.request?.url) {
        crucialInfo += `URL: ${errorResponse.meta.baseQueryMeta.request.url}\n`;
    }
    if (errorResponse.meta?.arg?.originalArgs) {
        crucialInfo += `Original Args: ${JSON.stringify(errorResponse.meta.arg.originalArgs)}\n`;
    }

    return crucialInfo;
};
