import { DragIndicatorOutlined } from '@mui/icons-material'
import { Box, Divider, Stack } from '@mui/material'
import React from 'react'
import { theme } from '../Theme'

const ResizableCustomHandle = () => {

    return (
        <Stack
            sx={{
                height: '100%',
            }}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Divider
                orientation='vertical'
                variant='middle'
                textAlign='center'
                sx={{
                    '&:hover, &:active': {
                        "&::before, &::after": {
                            borderColor: theme.palette.primary.main,
                            borderWidth: '5px'
                        },
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'

                }}
            >
                <Stack>
                    <DragIndicatorOutlined color='action' />
                </Stack>
            </Divider>
        </Stack>

    )
}

export default ResizableCustomHandle
