import React, { useState } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import {
  RichTextEditor,
  MenuControlsContainer,
  MenuButtonAlignCenter,
  MenuButtonAlignJustify,
  MenuButtonAlignLeft,
  MenuButtonAlignRight,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonBlockquote,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuDivider,
  TableImproved,
  MenuButtonAddTable,
  TableMenuControls,
} from 'mui-tiptap';
import { Box, keyframes } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import { Indent, getIndent, getOutdent } from './CustomExtensions/indent.ts';
import AiGenerate from './CustomExtensions/aiGenerate.js';
import PageBreak from './CustomExtensions/pageBreak.js';
import MenuButtonGenerate from './CustomButtons/MenuButtonGenerate.jsx';
import MenuButtonPageBreak from './CustomButtons/MenuButtonPageBreak.jsx';
import CustomFieldLoadingAnimation from '../../CustomFieldLoadingAnimation.jsx';

const EditorMenuControls = ({ editor, showGenerateButton, fieldName, documentId, user, setAiLoading, disabled }) => {
  // Helper function to prevent blur on button click
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      onMouseDown={handleMouseDown}
      sx={{
        animation: `${slideDown} 0.5s ease-out`,
      }}
    >
      <MenuControlsContainer>
        {showGenerateButton && (
          <>
            <MenuButtonGenerate
              fieldName={fieldName}
              documentId={documentId}
              user={user}
              onMouseDown={handleMouseDown}
              setAiLoading={setAiLoading}
              disabled={disabled}
            />
            <MenuDivider />
          </>
        )}
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        <MenuButtonUnindent
          tooltipLabel="Decrease indent"
          disabled={!editor?.isEditable}
          onClick={() => getOutdent()({ editor })}
        />
        <MenuButtonIndent
          tooltipLabel="Increase indent"
          disabled={!editor?.isEditable}
          onClick={() => getIndent()({ editor })}
        />
        <MenuDivider />
        <MenuButtonAlignLeft />
        <MenuButtonAlignCenter />
        <MenuButtonAlignRight />
        <MenuButtonAlignJustify />
        <MenuDivider />
        <MenuButtonPageBreak />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        <MenuButtonAddTable />
        {editor.isActive('table') && <TableMenuControls />}
      </MenuControlsContainer>
    </Box>
  );
};

const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 1;
  }
  to {
    max-height: 200px; /* Adjust this value based on the height you expect for the controls */
    opacity: 1;
  }
`;

const ConfiguredRichTextEditor = ({
  content,
  onChange,
  showGenerateButton = false,
  fieldName,
  disabled,
  documentId,
  user,
}) => {
  const extensions = [
    StarterKit,
    Underline,
    TextAlign.configure({ types: ['paragraph'] }),
    Indent,
    AiGenerate.extend({}), // extending with empty object so that a new instance of the extension with its storage is created per editor
    TextStyle,
    TableImproved.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    PageBreak,
  ];

  const [aiLoading, setAiLoading] = useState(false); // Local state for overlay

  return (
    <Box
      sx={{
        position: 'relative', // Set position to relative to contain the overlay
        '.ProseMirror': {
          minHeight: '100px',
          maxHeight: '350px',
          overflow: 'auto',
        },
      }}
    >
      {/* Loading overlay inside the editor container */}
      <CustomFieldLoadingAnimation aiLoading={aiLoading} />

      <RichTextEditor
        extensions={extensions}
        content={content}
        onUpdate={onChange}
        parseOptions={{ preserveWhitespace: 'full' }}
        renderControls={(editor) =>
          editor.isFocused && (
            <EditorMenuControls
              editor={editor}
              showGenerateButton={showGenerateButton}
              fieldName={fieldName}
              documentId={documentId}
              user={user}
              setAiLoading={setAiLoading}
              disabled={disabled}
            />
          )
        }
      />
    </Box>
  );
};

export default ConfiguredRichTextEditor;
