import { pushDataToS3, getUploadUrl, handleUpdateFilePdf, postMedicalsToFile, postPageInsightsToFile } from '../../../api';
import { setEditDocumentPdfOpen, setFileDisabled, setFileSaveStatusMap, setEditAllDocumentsOpen } from '../../../redux/slices/demandDomSlice';
import { setPdfUrlPollingLoadingStatus } from '../../../services/pdf/customSlice';
import { setToast } from '../../../redux/slices/globalToastSlice';
import { fetchMedicalsData } from '../../../redux/thunks/medicalsData';
import PSPDFKit from 'pspdfkit';
import ReactDOM from 'react-dom';
import { Button } from '@mui/material';
import { apiSlice } from '../../../services/apiSlice';
import { pollPdfRegenerationStatus } from '../../../services/pdf/thunks';

//Toolbar items that are removed from the toolbar in the PDF editor.
export const toolbarItemsToRemove = [
    "sidebar-document-outline", "sidebar-annotations", "sidebar-bookmarks", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser",
    "signature", "image", "stamp", "note", "text", "callout", "line", "link", "arrow", "rectangle", "ellipse", "polygon", "cloudy-polygon",
    "polyline", "document-crop", "multi-annotations-selection", "pan", "print", "sidebar-signatures", "sidebar-layers", "document-editor"
];

//Toolbar items that are added to the PDF editor toolbar.
export const editorItems = ['remove', 'undo', 'redo', 'select-all', 'select-none', 'zoom-out', 'zoom-in', 'rotate-left', 'rotate-right'];

export const handleViewStateChange = ({ dispatch, previousState, pdfEditorContainerRef, PSPDFKit }) => {
    if (previousState?.interactionMode?.toLowerCase() === "document_editor") {
        dispatch(setEditDocumentPdfOpen(false))
    }
}

const applyOperations = (originalArray, operations) => {
    let originalArrayCopy = [...originalArray];

    operations.forEach(operation => {
        if (operation.type === 'movePages') {
            const pageIndexesToMove = operation.pageIndexes.sort();
            const pageValuesToMove = pageIndexesToMove.map(pageIndex => originalArrayCopy[pageIndex]);

            pageIndexesToMove.forEach(page => {
                originalArrayCopy[page] = undefined;
            });

            if (operation?.beforePageIndex !== undefined) {
                const insertionPoint = Math.max(0, operation.beforePageIndex - 1);
                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
            } else if (operation?.afterPageIndex !== undefined) {
                const insertionPoint = Math.min(operation.afterPageIndex + 1, originalArrayCopy.length);
                originalArrayCopy.splice(insertionPoint, 0, ...pageValuesToMove);
            }

            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
        } else if (operation.type === 'removePages') {
            operation.pageIndexes.forEach(pageIndex => {
                originalArrayCopy[pageIndex] = undefined;
            });
            originalArrayCopy = originalArrayCopy.filter(val => val != undefined);
        }
    });

    return originalArrayCopy.map(val => val + 1);
};

export const handleDocumentChangeSave = async ({ dispatch, changes, instance, file, setIsSaving, fileSaveStatusMap, setLocalDocumentFiles, user, editOneDocumentFileName, documentId, pdfEditorContainerRef, PSPDFKit }) => {
    dispatch(setFileDisabled(file.fileEntityId));
    setIsSaving(true);
    dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saving' }));
    const totalPagesRemoved = changes.filter((change) => change.type === "removePages").map((change) => change.pageIndexes).flat().length;
    const currentPageCount = instance.totalPageCount;
    const originalPageCount = currentPageCount + totalPagesRemoved;
    const originalPageIndexArray = Array.from({ length: originalPageCount }, (_, index) => index);
    const pageNumbersMappedToCorrectIndices = applyOperations(originalPageIndexArray, changes);

    handleMedicalsUpdate({ pageNumbersMappedToCorrectIndices, instance, dispatch, fileId: file.fileEntityId, file, setLocalDocumentFiles, documentId: documentId, user, editOneDocumentFileName, fileSaveStatusMap, setIsSaving, pdfEditorContainerRef, PSPDFKit });
}

const handleSave = ({ documentId, dispatch, user, editOneDocumentFileName, file, fileSaveStatusMap, setIsSaving, pdfEditorContainerRef, PSPDFKit, setLocalDocumentFiles }) => {
    dispatch(pollPdfRegenerationStatus({ documentId, user }));
    dispatch(fetchMedicalsData({ documentId, user }))
        .then(async () => {
            const documentFiles = await dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }))
            .unwrap();

            setLocalDocumentFiles(documentFiles);
            dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Saved' }));
            setIsSaving(false);
            dispatch(setFileDisabled(''))
            dispatch(setToast({ isOpen: true, message: `Updated ${editOneDocumentFileName}`, severity: 'success' }));
            pdfEditorContainerRef && PSPDFKit.unload(pdfEditorContainerRef);
        })
    return;
}

const handleMedicalsUpdate = async ({ pageNumbersMappedToCorrectIndices, instance: originalInstance, dispatch, fileId, file, setLocalDocumentFiles, documentId, user, editOneDocumentFileName, fileSaveStatusMap, setIsSaving, pdfEditorContainerRef, PSPDFKit }) => {
    //create readoonly copy of instance
    const instance = { ...originalInstance };

    dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));

    dispatch(setEditAllDocumentsOpen(true));

    const medicalTreatments = file?.fileEntityData?.medicalTreatments || [];
    const pageInsights = file?.fileEntityData?.pageInsights || [];


    const getUploadFieldsAndRegenerate = async () => {

        const uploadFields = await getUploadUrl(editOneDocumentFileName, documentId, user)

        const pdf = await instance.exportPDF()
            .then((buffer) => new Blob([buffer], { type: 'application/pdf' }));

        const success = await pushDataToS3(uploadFields, pdf)

        if (success) {
            handleUpdateFilePdf(fileId, editOneDocumentFileName, user)
                .then(async (response) => {
                    handleSave({ documentId, dispatch, user, editOneDocumentFileName, file, fileSaveStatusMap, setIsSaving, PSPDFKit, pdfEditorContainerRef, setLocalDocumentFiles });
                })
                .catch((error) => {
                    dispatch(setToast({ isOpen: true, message: 'Error saving document', severity: 'error' }));
                    const fileSaveStatusMapCopy = { ...fileSaveStatusMap };
                    delete fileSaveStatusMapCopy[file.fileEntityId];
                    dispatch(setFileSaveStatusMap(fileSaveStatusMapCopy))
                })
        }
    }

    const medicalsWithUpdatedPageNumbers = medicalTreatments.map(medical => {
        const currentPageNumber = medical.sourcePageNumber;
        const newPageNumber = pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) !== - 1 ? pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) + 1 : - 1;
        return {
            ...medical,
            sourcePageNumber: newPageNumber
        }
    })
        .filter(medical => medical.sourcePageNumber !== -1);

    const pageInsightsWithUpdatedPageNumbers = pageInsights.map(pageInsight => {
        const currentPageNumber = pageInsight.page_number;
        const newPageNumber = pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) !== - 1 ? pageNumbersMappedToCorrectIndices.indexOf(currentPageNumber) + 1 : - 1;
        return {
            ...pageInsight,
            page_number: newPageNumber
        }
    })
        .filter(pageInsight => pageInsight.page_number !== -1);



    const hasUpdatedMedicals = medicalsWithUpdatedPageNumbers.length > 0;
    const hasUpdatedPageInsights = pageInsightsWithUpdatedPageNumbers.length > 0;

    if (hasUpdatedMedicals) {
        await postMedicalsToFile(fileId, { medicalTreatments: medicalsWithUpdatedPageNumbers }, user)
            .then(async (response) => {
                if (hasUpdatedPageInsights) {
                    await postPageInsightsToFile(fileId, { pageInsights: pageInsightsWithUpdatedPageNumbers }, user)
                        .then(async (response) => {
                            getUploadFieldsAndRegenerate();
                            return response
                        })
                        .catch(error => error)
                } else {
                    getUploadFieldsAndRegenerate();
                }
                return response
            })
            .catch(error => error)
    } else {
        if (hasUpdatedPageInsights) {
            await postPageInsightsToFile(fileId, { pageInsights: pageInsightsWithUpdatedPageNumbers }, user)
                .then(async (response) => {
                    getUploadFieldsAndRegenerate();
                    return response
                })
                .catch(error => error)
        } else {
            getUploadFieldsAndRegenerate();
            return;
        }
    }
}

export const renderMUICustomCancelButton = ({ parentNode, dispatch, pdfEditorContainerRef }) => {

    const styles = {
        margin: '0 auto',
        alignItems: 'flex-start',
        alignItems: 'center',
        color: '#000',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
        display: 'block',
        fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        height: '37.4px',
        padding: '6.5px 13px',
        textAlign: 'center',
        transition: 'all 0.1s ease',
    };



    const CustomCancelButton = () => (
        <Button
            variant="outlined"
            size='large'
            style={styles}
            onClick={() => {
                dispatch(setEditDocumentPdfOpen(false));
                pdfEditorContainerRef.current && PSPDFKit.unload(pdfEditorContainerRef.current);
            }}
        >
            Cancel
        </Button>
    );

    // Render the MUI Button into the parentNode
    ReactDOM.render(<CustomCancelButton />, parentNode);
};