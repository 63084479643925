import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography, Stack, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteMedicalOpen } from '../redux/slices/demandDomSlice';
import { Transition } from '../Transition';

const DeleteMedical = ({ deleteMedical }) => {

    const dispatch = useDispatch();
    const deleteMedicalOpen = useSelector((state) => state.DemandDom.deleteMedicalOpen);
    const currentDeleteMedical = useSelector((state) => state.MedicalsTreatments.currentDeleteMedical);
    const handleDeleteMedicalClose = () => dispatch(setDeleteMedicalOpen(false));

    return (
        <Dialog
            open={deleteMedicalOpen}
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xs"
        >
            <DialogContent>
                <Stack
                    spacing={2}
                >
                    <Typography variant="body1">
                        You are about to permanently delete: <Box sx={{ fontWeight: 'bold' }}>{currentDeleteMedical["treatmentFacility"]} - {currentDeleteMedical["treatmentType"]}</Box>
                    </Typography>
                    <Typography variant="body1">
                        Are you sure you want to continue?
                    </Typography>
                </Stack>
                <DialogContentText id="alert-dialog-slide-description">

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => { handleDeleteMedicalClose() }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => { deleteMedical() }}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteMedical;