import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMedicals, postMedicals } from '../../api';
import { setPdfUrlPollingLoadingStatus } from '../../services/pdf/customSlice';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';

export const fetchMedicalsData = createAsyncThunk('Medicals/fetchMedicalsData', async ({ documentId, user }) => {
    return getMedicals(documentId, user)
        .then((response) => response.json())
        .catch(err => err);
});

export const postMedicalsData = createAsyncThunk('Medicals/postMedicalsData', async ({ documentId, medicals, user }, { dispatch }) => {
    dispatch(setPdfUrlPollingLoadingStatus({[documentId]: true}));
    return postMedicals(documentId, medicals, user)
        .then((response) => {
            dispatch(pollPdfRegenerationStatus({ documentId, user }));
            dispatch(fetchMedicalsData({ documentId, user }));
            return response.json()
        })
        .catch(err => err);
});