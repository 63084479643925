import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiSlice } from '../apiSlice';
import { setPdfUrlPollingLoadingStatus } from './customSlice';
import { fetchMedicalsData } from '../../redux/thunks/medicalsData'

//  TODO: remove this thunk and create a custom queryFn,
//   after that remove the customSlice state managment and use the query state instead
export const pollPdfRegenerationStatus = createAsyncThunk( 
    'PdfCustomSlice/pollPdfRegenerationStatus',
    async ({ documentId, user }, { dispatch }) => {
        const pollPdfRegeneration = async (documentId, user) => {
            dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));
            const response = await dispatch(apiSlice.endpoints.getPdfRegenerationStatus
                .initiate(documentId, { forceRefetch: true }))
                .unwrap();
            if (response.status === 'Complete') {
                //stop polling loading, refetch pdf url, medicals data, and all files
                dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: false }));
                dispatch(apiSlice.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true }));
                dispatch(fetchMedicalsData({ documentId, user }, { forceRefetch: true })); 
                dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
            } else {
                setTimeout(() => {
                    pollPdfRegeneration(documentId, user);
                }, 3000);
            }
        }
        setTimeout(() => {
            pollPdfRegeneration(documentId, user);
        }, 5000);
    });